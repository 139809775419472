import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppWrapper from "./AppWrapper";
import ThemeContextWrapper from "./ThemeContextWrapper";
import GobyContextWrapper from "./GobyContextWrapper";

ReactDOM.render(
  <GobyContextWrapper>
    <ThemeContextWrapper>
      <React.StrictMode>
        <AppWrapper />
      </React.StrictMode>
    </ThemeContextWrapper>
  </GobyContextWrapper>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
